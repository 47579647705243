import AdaptiveCardsStyleOptions from './AdaptiveCardsStyleOptions';

const ADAPTIVE_CARDS_DEFAULT_STYLE_OPTIONS: Required<AdaptiveCardsStyleOptions> = {
  cardEmphasisBackgroundColor: '#F0F0F0',
  cardPushButtonBackgroundColor: '#0063B1',
  cardPushButtonTextColor: 'white',
  richCardWrapTitle: false
};

export default ADAPTIVE_CARDS_DEFAULT_STYLE_OPTIONS;
